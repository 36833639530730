import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import {Box, Typography} from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partnerData from '../assets/json/partners.json';
import useStyles from '../styles/styles';

const Partner = () => {
    const [partners, setPartners] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        setPartners(partnerData);
    }, []);

    // count how many partners
    const partnerCount = partners.length;
    let slidesToShow;

    if (partnerCount <= 3) {
        slidesToShow = 1
    } else {
        slidesToShow = 3
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 3}},
            {breakpoint: 600, settings: {slidesToShow: 2}},
            {breakpoint: 480, settings: {slidesToShow: 1}}
        ]
    };

    const getImagePath = (logo) => {
        try {
            return require(`../assets/img/partners/${logo}`).default;
        } catch (err) {
            console.error(`Partner image not found: ${logo}`);
            return null;
        }
    };

    return (
        <Box
            sx={{
                padding: '20px',
                textAlign: 'center',
                overflowX: 'hidden',
                overflowY: 'hidden',
                maxWidth: '100%',
                margin: '0 auto',
            }}
        >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
                Our Partners
            </Typography>
            <Slider {...sliderSettings}>
                {partners.map((partner, index) => (
                    <Box key={index} className={classes.partnerBox}>
                        <img
                            src={getImagePath(partner.logo)}
                            alt={partner.name}
                            style={{width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'contain'}}
                        />
                        <Typography variant="h6" sx={{marginTop: '10px'}}>
                            {partner.name}
                        </Typography>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default Partner;
